import { render, staticRenderFns } from "./addPtsModal.vue?vue&type=template&id=1126ad40&scoped=true&"
import script from "./addPtsModal.vue?vue&type=script&lang=js&"
export * from "./addPtsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1126ad40",
  null
  
)

export default component.exports