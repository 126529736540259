<!-- 访视管理 -->
<!-- <template>
  <!-- <div> -->
    <!-- <a-tabs tab-position="left">
      <!-- <template v-for="item in tabs"> -->
        <!-- <a-tab-pane :key="item.id" :tab="'版本' + item.name" @tabClick="activeVisitId(item.id)"> -->
          <!-- 表格开始 -->
          <!-- <a-table :columns="columns" bordered :data-source="item.visitVersionList" :pagination="false">
            <template slot="title"> -->
              <!-- 标题 -->
              <!-- <a-descriptions :title="'版本' + item.name"></a-descriptions>
            </template>
          </a-table>
        </a-tab-pane>
      </template>
    </a-tabs>
  </div>
</template> -->
<template>
  <div>
    <a-table ref="table" :data-source="tabs" bordered>
      <a-table-column title="序号" align="center" data-index="id">
        <template slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
      </a-table-column>
      <a-table-column title="签属文件" align="center" data-index="fileName"/>
      <a-table-column title="发起人" align="center" data-index="createName"/>
      <a-table-column title="发起时间" align="center" data-index="createTime">
        <template slot-scope="text, record">
          {{ record.createTime | moment }}
        </template>
      </a-table-column>
      <a-table-column title="当前状态" align="center" data-index="status">
        <template slot-scope="text, record">
          {{ record.status | filterStatuser }}
        </template>
      </a-table-column>
      <a-table-column title="操作" align="center">
        <template slot-scope="text, record">
          <a href="javascript:;" @click="goToMessage(record)">查看</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import { list } from '@/api/overview/subject'
const columns = [
  {
    title: '序号',
    dataIndex: 'id',
    render: (text, record, index) => `${index + 1}`
  },
  {
    title: '签属文件',
    dataIndex: 'fileName'
  },
  {
    title: '发起人',
    dataIndex: 'createName'
  },
  {
    title: '发起时间',
    dataIndex: 'createTime'
  },
  {
    title: '当前状态',
    dataIndex: 'status'
  },
  {
    title: '操作',
    dataIndex: 'address',
    scopedSlots: { customRender: 'address' }
  }
]
export default {
  name: '',
  props: {
    ptsDetailsList: {
      type: String,
      default: ''
    },
    proId: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      visible: false, // 访视标识
      tabs: [],
      visitId: '',
      columns,
      modalTitle: ''
    }
  },
  watch: {
    ptsDetailsList (newValue) {
      if (newValue) {
        this.getList()
      }
    }
  },
  mounted () {
    this.getList()
  },
  filters: {
    filterStatuser (val) {
      if (val === 1) {
        return '待查阅'
      } else if (val === 2) {
        return '待沟通'
      } else if (val === 3) {
        return '待知情'
      } else if (val === 4) {
        return '待签署'
      } else if (val === 5) {
        return '已完成'
      } else if (val === 6) {
        return '已拒绝'
      }
    }
  },
  methods: {
    getList () {
      if (!this.ptsDetailsList) {
        return
      }
      list({
        proId: this.proId,
        ptsNo: this.ptsDetailsList
      }).then(res => {
        console.log(res)
        this.tabs = res
      })
    },
    goToMessage (value) {
      this.$emit('goToMessage', value)
    }
  }
}
</script>
<style scoped lang="less">
// .ant-tabs .ant-tabs-left-bar .ant-tabs-tab,
// .ant-tabs .ant-tabs-right-bar .ant-tabs-tab {
//   padding: 8px 64px;
// }
// .flex-center {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
</style>
