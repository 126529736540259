<template>
  <div>
    <div class="menu-header">
      <a-icon class="arrow" type="arrow-left" @click="$router.go(-1)" />
      <div class="titleView">
        <div>项目编号:</div>
        <div class="label font">{{ data.no }}</div>
        <div class="status" >
          <span class="pro-status" :class="data.status | enumValueByKey($const.PROJECT_STATUS,'style')" >
            {{ data.status | enumValueByKey($const.PROJECT_STATUS) }}
          </span>
        </div>

      </div>
    </div>
    <div class="project-title">
      <span class="pro-name">{{ data.name || '' }}</span>
    </div>
    <div class="bottom-view">
      <div class="bottom">
        <div style="margin-right:10px">研究中心:</div>
        <div
          style="flex:1;width:0">
          <a-select v-model="orgId" @change="orgChange" style="max-width:100%;width:100%" placeholder="请选择">
            <a-select-option v-for="(item,index) in orgList" :key="index" :value="item.orgId" :disabled="item.status != 1" >
              {{ item.researchCenterNo }}<span style="margin-left:20px" >{{ item.orgName }}</span>
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="top">
        <div class="left">
          <div>知情流程:</div>
          <a-select v-model="process" style="width:60%;margin-left:10px" placeholder="请选择" disabled>
            <a-select-option v-for="(item,index) in processList" :key="index" :value="item.value" >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="right">
          <span style="margin-right:10px">知情研究者: </span>
          <a-select v-model="researcherId" style="width:60%" placeholder="请选择">
            <a-select-option v-for="(item,index) in researcherList" :key="index" :value="item.researchNo">
              {{ item.name }}
            </a-select-option>
          </a-select>

        </div>
      </div>
      <div class="bottom">
        <div style="margin-right:10px">知情版本: </div>
        <div
          style="flex:1;width:0">
          <a-select style="max-width:100%;width:100%" v-model="icfId" @change="icfChange" placeholder="请选择">
            <a-select-option v-for="(item,index) in icfList" :key="index" :value="item.id">
              {{ item.fileName }}
            </a-select-option>
          </a-select>
        </div>
        <a-button type="primary" style="margin-left:10px" @click="fileDownload" :disabled="!icfId">文件下载</a-button>
      </div>
    </div>
    <div class="subView">
      <div class="subTitle">参与者签署</div>
      <div>
        参与者: <span class="large">{{ allNum || 0 }}</span> / <span class="large">{{ noKnowNum || 0 }}</span>人待知情
      </div>
    </div>
    <a-table
      v-if="$auth('PAD.PAD_TESTEE_KNOW')"
      ref="table"
      size="default"
      :pagination="false"
      :columns="columns"
      :dataSource="dataList">
      <template slot="icfStatus" slot-scope="text, record">
        {{ record.icfStatus | enumValueByKey($const.ICF_STATUS) }}
      </template>
      <template slot="researchCenter" slot-scope="text, record">
        <div>{{ record.researchCenterNo }}</div>
        <div>{{ record.orgName }}</div>
      </template>
      <template slot="ptsNo" slot-scope="text, record">
        <div>
          <a @click="toPtsInfo(record)">{{ record.ptsNo }}</a>
        </div>
        <div>{{ record.name }}</div>
        <div>{{ record.phone }}</div>

      </template>

      <template slot="operation" slot-scope="text, record">
        <a v-if="record.icfStatus != 3 && $auth('PAD.PAD_TESTEE_KNOW_DETAIL')" @click="toConsentDetail(record)" class="btn" >查看详情</a>

        <!-- 待知情 或者 已知情(pad发起) -->
        <a v-if="record.icfStatus == 3 && $auth('PAD.PAD_TESTEE_CHECK_DETAIL')" @click="toConsentPreview(record)" class="btn" >知情签署</a>
        <!-- <a
          v-if="(record.icfStatus == 0 || (record.icfStatus == 10 && record.signType == 2 )) && (icfConfig === 3 || record.id ) && $auth('PROJECT_LIST.CHECK_DETAIL')"
          class="btn"
          :loading="btnLoading"
          @click="toSign(record)"
        >知情签署</a> -->
      </template>
    </a-table>
    <a-button v-if="$auth('PAD.TESTEE_MANAGE_ADD')" type="primary" style="margin-top:10px" @click="subjectVisible = true" ghost >添加参与者</a-button>
    <!-- 添加参与者 -->
    <!-- <AddSubject
      ref="addSubject"
      :visible="subjectVisible"
      :site="site"
      @ok="handleOk"
      @cancel="subjectVisible = false"
    ></AddSubject> -->
    <AddPts
      ref="addSubject"
      :visible="subjectVisible"
      :research-id="researchId"
      @ok="handleOk"
      @cancel="subjectVisible = false"
    />
    <ViewModal
      :visible="infoVisible"
      :is-edit="viewEdit"
      @close="infoVisible = false"
      @closeViewModal="closeViewModal"
      @goToMessage="goToMessage"
      :uId="uId"
      :thisStatus="thisStatus"
      :proId="proId"
    ></ViewModal>
    <!-- 查看弹窗中的查看弹窗 -->
    <ViewModalser
      :visible="viewVisibles"
      @close="viewVisibles = false"
      :sign-id="signId"
    ></ViewModalser>

    <!-- <ViewPtsInfoModal
      ref="infoSubject"
      :visible="infoVisible"
      @cancel="infoVisible = false"
    /> -->
  </div>

</template>
<script>
import { addPts, icfApply, startSign } from '@/api/overview/subject'
import { getProDetail, getIcfList, exportPDF } from '@/api/overview/overview'
import { getConsentList } from '@/api/consent/consent'
import { exportPdf } from '@/utils/util'
// import { download } from '@/utils/util'
// import AddSubject from './components/addSubjectModal.vue'
import AddPts from './components/addPtsModal.vue'
import ViewModal from './components/viewModal.vue'
import ViewModalser from './components/viewModalser.vue'

export default {
  components: {
    // AddSubject
    AddPts,
    ViewModal,
    ViewModalser
  },
  data () {
    return {
      btnLoading: false,
      icfConfig: '',
      isToSign: false,
      subjectVisible: false,
      infoVisible: false,
      viewVisibles: false,
      viewEdit: false,
      uId: '',
      thisStatus: '',
      signId: '',
      site: {},
      proId: '',
      orgId: '',
      process: '',
      researchId: '',
      researcherList: [],
      researcherId: '',
      icfId: '',
      icfUrl: '',
      data: {},
      allNum: 0,
      noKnowNum: 0,
      dataList: [],
      orgList: [],
      icfList: [],
      processList: [
        {
          value: 1,
          name: '标准合规版本'
        },
        {
          value: 2,
          name: '易于理解版本'
        },
        {
          value: 3,
          name: '简易版本'
        },
        {
          value: 4,
          name: '视频沟通版本'
        },
        {
          value: 5,
          name: '混合模式'
        }
      ],
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          customRender: (text, record, index) => `${index + 1}`
        },
        {
          title: '研究中心',
          dataIndex: 'researchCenter',
          scopedSlots: { customRender: 'researchCenter' }
        },
        {
          title: '参与者信息',
          dataIndex: 'ptsNo',
          scopedSlots: { customRender: 'ptsNo' }
        },
        // {
        //   title: '姓名',
        //   dataIndex: 'name'
        // },
        // {
        //   title: '电话/手机',
        //   dataIndex: 'phone'
        // },
        {
          title: '知情状态',
          dataIndex: 'icfStatus',
          scopedSlots: { customRender: 'icfStatus' }
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: 180,
          scopedSlots: { customRender: 'operation' }
        }

      ],
      loadData: (parameter) => {
        return getConsentList(Object.assign(parameter, this.queryParam))
          .then((res) => {
            console.log(res, '获取的签署文件列表信息')
            return res
          })
          .catch(() => {})
      }
    }
  },
  created () {
    this.initData()
  },
  activated () {
    if (this.proId !== this.$route.query.id || this.orgId !== this.$route.query.orgId) {
      // 切换项目了
      this.initData()
    }

    if (this.isToSign) {
      this.getProDetail()
      this.isToSign = false
    }
  },
  methods: {
    initData () {
      this.proId = this.$route.query.id
      this.orgId = this.$route.query.orgId
      this.orgId && (this.orgId = Number(this.orgId))
      this.researchId = this.$route.query.researchId
      this.researcherId = ''
      this.icfId = ''
      this.dataList = []
      this.getProDetail()
    },
    // 关闭弹窗
    closeViewModal () {
      this.infoVisible = false
      this.getProDetail()
    },
    toPtsInfo (record) {
      this.uId = record.ptsId
      this.thisStatus = record.authStatus
      this.infoVisible = true
    },
    goToMessage (value) {
      this.viewVisibles = true
      this.signId = value.id
    },
    handleOk (val, type) {
      this.$refs.addSubject.confirmLoading = true
      addPts({
        proId: this.proId,
        proManagePtsList: JSON.stringify(val),
        type: type
      }).then((res) => {
        this.$refs.addSubject.confirmLoading = false
        this.$message.success('操作成功！')
        this.$refs.addSubject.data = [] // 清空数组
        this.subjectVisible = false
        this.getProDetail()
      }).catch(() => {
        this.$refs.addSubject.confirmLoading = false
      })
    },
    orgChange () {
      if (this.orgList && this.orgList.length) {
        const site = this.orgList.find(item => {
          return item.orgId === Number(this.orgId)
        })
        if (site) {
          this.researchId = site.researchId
        }
      }
      this.getProDetail()
    },
    icfChange (e) {
      if (this.icfList && this.icfList.length) {
        const icfRecord = this.icfList.find(item => {
          return item.id === Number(e)
        })
        if (icfRecord) {
          this.icfConfig = icfRecord.icfConfig
          this.icfUrl = icfRecord.fileUrl
        }
      }
      this.getIcfList()
    },
    getProDetail () {
      getProDetail({
        id: this.proId,
        researchId: this.researchId
      }).then(res => {
        this.data = res
        this.process = res.icfConfig
        this.orgList = []
        if (res.orgList && res.orgList.length) {
          this.orgList = res.orgList
        }

        this.researcherList = []
        this.researcherId = ''
        if (res.researcherList && res.researcherList.length) {
          this.researcherList = res.researcherList
          if (!this.researcherId) {
            this.researcherId = this.researcherList[0].researchNo
          }
        }
        this.icfList = []
        this.icfId = ''
        if (res.icfList && res.icfList.length) {
          this.icfList = res.icfList
          if (!this.icfId) {
            this.icfId = this.icfList[0].id
            this.icfConfig = this.icfList[0].icfConfig
            this.icfUrl = this.icfList[0].fileUrl
          }
        }
        this.getIcfList()
      })
    },
    getIcfList (type = 0, id = 0) {
      getIcfList({
        proId: this.proId,
        researchId: this.researchId,
        icfId: this.icfId
      }).then(res => {
        this.allNum = res.allNum
        this.noKnowNum = res.noKnowNum
        this.dataList = res.list
        // if (type === 1 && id && this.dataList) {
        //   // 跳转到知情
        //   const pts = this.dataList.find(item => {
        //     return item.ptsId === Number(id)
        //   })
        //   if (pts) {
        //     this.$router.push({
        //       name: 'ConsentPreview',
        //       query: {
        //         icfId: pts.icfId,
        //         proId: pts.proId,
        //         ptsNo: pts.ptsNo,
        //         id: pts.id
        //       }
        //     })
        //   }
        // }
        // if (type === 2 && id && this.dataList) {
        //   // 跳转到签署
        //   const pts = this.dataList.find(item => {
        //     return item.ptsId === Number(id)
        //   })
        //   if (pts) {
        //     this.isToSign = true
        //     this.$router.push({
        //       name: 'ConsentSign',
        //       query: {
        //         proId: pts.proId,
        //         researchId: pts.researchId,
        //         icfId: pts.icfId,
        //         id: pts.id
        //       }
        //     })
        //   }
        // }
      })
    },
    // 跳转到签署详情
    toConsentDetail (record) {
      this.$router.push({
        name: 'ConsentDetail',
        query: { id: record.id }
      })
    },
    goSign (record) {
      console.log(this.process + ':sadfvasdvasdvsdvasd')
      if (Number(this.process) === 3 || Number(this.process) === 4) {
        this.$router.push({
          name: 'ConsentSign',
          query: {
            proId: record.proId,
            researchId: record.researchId,
            icfId: record.icfId,
            researcherId: this.researcherId,
            id: record.id
          }
        })
      } else {
        this.$router.push({
          name: 'ConsentPreview',
          query: {
            icfId: record.icfId,
            proId: record.proId,
            ptsNo: record.ptsNo,
            researcherId: this.researcherId,
            id: record.id
          }
        })
      }
    },
    // 知情签署
    async toConsentPreview (record) {
      const arr = record
      if (!record.id) {
        const res = await icfApply({
          proId: this.proId,
          orgId: this.orgId,
          icfId: this.icfId,
          ptsNo: record.ptsNo,
          researcherNo: this.researcherId
        })
        this.getIcfList()
        arr.id = res.id
        arr.icfId = res.icfId
        this.goSign(arr)
      } else {
        this.goSign(record)
      }
      this.goSign(record)
    },
    // 签署文件
    toSign (record) {
      if (record.flowId) {
        this.isToSign = true
        this.$router.push({
          name: 'ConsentSign',
          query: {
            proId: record.proId,
            researchId: record.researchId,
            icfId: record.icfId,
            researcherId: this.researcherId,
            id: record.id
          }
        })
      } else {
        if (this.researcherId) {
          this.btnLoading = true
          if (this.icfConfig === 3 && !record.id) {
            // 简易版本,直接签署 需要先申请
            icfApply({
              proId: this.proId,
              orgId: this.orgId,
              icfId: this.icfId,
              ptsNo: record.ptsNo
            }).then(res => {
              // 获取申请id
              record.id = res
              this.startToSign(record)
            }).catch(_ => {
              this.btnLoading = false
            })
          } else {
            this.startToSign(record)
          }
        } else {
          this.$message.error('当前site没有研究者成员')
        }
      }
    },
    startToSign (record) {
      // 发起签署
      startSign({
        applyId: record.id,
        researcherNo: this.researcherId
      }).then(res => {
        this.getIcfList(2, record.ptsId)
      }).finally(() => {
        this.btnLoading = false
      })
    },
    // 文件下载
    async fileDownload () {
      const res = await exportPDF({ fileName: this.icfUrl })
      this.$message.success('下载成功')
      exportPdf('知情文件', res)
    }
  }
}
</script>
<style lang="less" scoped>
  .titleView{
    display: flex;
    flex: 1;
    .label{
      width: 30%;
      color: #0099FF;
      word-break: break-all;
      word-wrap: break-word;

    }
    .font{
      margin-left: 10px;
      font-weight: 500;
    }
    .status{
      flex: 1;
      text-align: right;
      .pro-status {
        flex: none;
      }
    }

  }
  .project-title{
    margin-top: 10px;
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: flex-start;
    .pro-name {
      word-break: break-all;
      word-wrap: break-word;
    }

  }
  .bottom-view{
    margin-top: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eaeaea;
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 10px 0;
      .left{
        flex: 1;
        display: flex;
        align-items: center;
      }
      .right{
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .bottom{
      display: flex;
      align-items: center;

    }

  }
  .subView{
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
    align-items: center;
    font-size: 16px;
    .subTitle{
      font-size: 22px;
      font-weight: 500;
      color: #000;
      font-weight: 500;
    }
    .large{
      font-size: 25px;
      font-weight: 500;
    }
  }

  .btn{
    margin-right: 15px;
  }
</style>
