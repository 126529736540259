<!--  -->
<template>
  <a-modal
    :visible="visible"
    title="签署详情"
    :footer="null"
    @cancel="handleCancel"
    width="80%"
    :centered="true"
  >
    <a-descriptions bordered size="small">
      <a-descriptions-item label="参与者ID" :span="3">
        <span>{{ detailDescription.ptsNo }}</span>
        <span style="font-weight: bold;">{{ detailDescription.fileName }}</span>
        <span style="font-weight: bold; margin-left: 5px;">{{ detailDescription.icfName }}</span>
        <span style="font-weight: bold; margin-left: 10px;">{{ detailDescription.createTime | moment('YYYY-MM-DD') }}</span>
        <span style="display: inline-block; border: 1px solid rgb(112,182,3); border-radius: 40px; padding: 3px 10px; color: rgb(112,182,3); margin-left: 30px;">{{ detailDescription.status | enumValueByKey($const.SIGNATURE_STATUS) }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="项目ID"> {{ detailDescription.proNo }} </a-descriptions-item>
      <a-descriptions-item label="site编号"> {{ detailDescription.orgNo }} </a-descriptions-item>
      <a-descriptions-item label="site负责人"> {{ detailDescription.researcherName }} </a-descriptions-item>
      <a-descriptions-item label="知情版本"> {{ detailDescription.icfName }} </a-descriptions-item>
      <a-descriptions-item label="当前状态"> {{ detailDescription.status | enumValueByKey($const.SIGNATURE_STATUS) }} </a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>
<script>
import { getConsentDetail } from '@/api/consent/consent'
export default {
  name: 'ViewModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    goToMessageValue: {
      type: Object,
      default: null
    },
    signId: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      detailDescription: {}
    }
  },
  filters: {
    filterGender (val) {
      if (val === 1) {
        return '待查阅'
      } else if (val === 2) {
        return '待沟通'
      } else if (val === 3) {
        return '待知情'
      } else if (val === 4) {
        return '待签署'
      } else if (val === 5) {
        return '已完成'
      } else if (val === 6) {
        return '已拒绝'
      }
    }
  },
  watch: {

    visible (e) {
      if (e && this.signId) {
        this.getConsentDetail()
      } else {
        this.detailDescription = {}
      }
    }
  },
  mounted () {
  },

  methods: {
    // 关闭回调
    handleCancel () {
      this.$emit('close')
    },
    getConsentDetail () {
      getConsentDetail({ id: this.signId }).then(res => {
        this.detailDescription = res.applyDto
      })
    }
  }
}
</script>
<style scoped lang="less">
</style>
