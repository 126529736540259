<!--  -->
<template>
  <a-modal :visible="visible" title="" :footer="null" @cancel="handleCancel" width="80%">
    <a-descriptions title="基本信息" bordered size="small">
      <a-descriptions-item label="参与者ID"> {{ PtsDetailsList.ptsNo }} </a-descriptions-item>

      <a-descriptions-item v-if="$options.filters.isPtsVisible()" label="姓名"> {{ PtsDetailsList.ptsName }} </a-descriptions-item>
      <a-descriptions-item v-if="$options.filters.isPtsVisible()" label="电话/手机"> {{ PtsDetailsList.ptsPhone }} </a-descriptions-item>
      <a-descriptions-item v-if="$options.filters.isPtsVisible()" label="身份证号码"> {{ PtsDetailsList.ptsIdentity }} </a-descriptions-item>

      <a-descriptions-item label="性别"> {{ PtsDetailsList.ptsGender | filterGender }} </a-descriptions-item>
      <a-descriptions-item label="年龄"> {{ PtsDetailsList.year }} </a-descriptions-item>
      <a-descriptions-item label="所属医院"> {{ PtsDetailsList.orgName }} </a-descriptions-item>
      <!-- <a-descriptions-item label="所属科室"> {{ PtsDetailsList.ptsNo }} </a-descriptions-item> -->
      <a-descriptions-item label="所属CRC"> {{ PtsDetailsList.inviteUserNo }} </a-descriptions-item>
      <a-descriptions-item label="中心负责人"> {{ PtsDetailsList.principalName }} </a-descriptions-item>
      <a-descriptions-item v-if="PtsDetailsList.refuseReason" label="拒绝理由"> {{ PtsDetailsList.refuseReason }} </a-descriptions-item>
      <a-descriptions-item v-if="!isEdit && thisStatus == 1" label="当前状态"> {{ getStatusList(statusList,form.status) }} </a-descriptions-item>
      <a-descriptions-item v-if="!isEdit && thisStatus == 1" label="研究人员"> {{ getStatusList(theDoctorList,form.reId) }} </a-descriptions-item>
    </a-descriptions>
    <div v-if="isEdit" class="table-page-search-wrappers">
      <!-- 筛选区域 -->
      <a-form layout="inline" v-if="thisStatus == 1" style="margin-top: 40px">
        <a-row :gutter="48">
          <a-col :md="10" :sm="24">
            <a-form-item label="当前状态">
              <a-select v-model="form.status" allowClear >
                <a-select-option
                  v-for="item in statusList"
                  :value="item.id"
                  :key="item.id"
                  @click="switchitem(item, 'status')"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="10" :sm="24">
            <a-form-item label="研究人员">
              <a-select v-model="form.reId" allowClear >
                <a-select-option
                  v-for="item in theDoctorList"
                  :value="item.id"
                  :key="item.id"
                  @click="switchitem(item, 'reId')"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div
      style="margin-top:20px"
      v-if="thisStatus == 5">
      <a-button
        type="primary"
        @click="clickReinvite" >邀请</a-button>
    </div>
    <!-- v-if="thisStatus == 1" -->
    <a-descriptions title="知情签署管理" style="margin-top: 20px" >
      <!-- <span>访视版本</span> -->
    </a-descriptions>
    <!-- v-if="thisStatus == 1"  -->
    <VisitTable :proId="proId" :ptsDetailsList="PtsDetailsList.ptsNo" @goToMessage="goToMessage"></VisitTable>
    <!-- 注释审核 -->
    <!-- <div v-if="thisStatus == 3 && $auth('PROJECT.PROJECT_PATIENT_APPROVE')" style="margin-top: 30px">
      <div style="width:40%;margin-bottom:15px">
        <a-input v-model="refuseReason" type="textarea" :maxLength="80" placeholder="拒绝理由" />
        <div style="text-align:right" >{{ refuseReason ? refuseReason.length : 0 }}/80</div>
      </div>
      <a-button type="primary" @click="agreement(1)" style="margin-right: 30px">同意</a-button>
      <a-button type="danger" @click="agreement(2)">拒绝</a-button>
    </div> -->

  </a-modal>
</template>

<script>
import VisitTable from './visitTable.vue'
import { PtsDetails, doctorList, upDatePtsStatus, updatePtsReId, updatePtsAuth, reinviteSubject } from '@/api/overview/subject'
export default {
  name: 'ViewModal',
  components: {
    VisitTable
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    uId: {
      type: String,
      default: ''
    },
    // thisStatus: {
    //   type: String,
    //   default: ''
    // },
    isEdit: {
      type: Boolean,
      default: false
    },
    proId: {
      type: [Number, String],
      default: ''
    }
  },
  data () {
    return {
      thisStatus: '',
      refuseReason: '',
      infoObj: {},
      form: {
        status: '',
        reId: ''
      },
      ptsNo: '',
      reId: '',
      statusList: [
        {
          id: 1,
          name: '待筛选'
        },
        {
          id: 2,
          name: '筛选中'
        },
        {
          id: 3,
          name: '筛选失败'
        },
        {
          id: 4,
          name: '随机'
        },
        {
          id: 5,
          name: '治疗中'
        },
        {
          id: 6,
          name: '退出'
        },
        {
          id: 7,
          name: '治疗结束'
        },
        {
          id: 8,
          name: '安全性随访'
        },
        {
          id: 9,
          name: '生存随访'
        }
      ],
      labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      PtsDetailsList: {}, // 参与者详细信息
      theDoctorList: [] // 显示当前项目研究人员
    }
  },
  filters: {
    filterGender (val) {
      if (val === -1) {
        return '未知'
      } else if (val === 0) {
        return '女'
      } else if (val === 1) {
        return '男'
      }
    }
  },
  watch: {
    visible (newValue) {
      if (newValue) {
        this.getPtsDetails({ id: this.uId })
      } else {
        this.thisStatus = ''
      }
    }
  },
  mounted () {

  },

  methods: {
    // 邀请
    clickReinvite () {
      if (this.PtsDetailsList) {
        reinviteSubject({ id: this.PtsDetailsList.id,
        ptsNo: this.PtsDetailsList.ptsNo,
        proId: this.proId,
        status: this.PtsDetailsList.authStatus })
        .then(res => {
          this.$message.success('邀请成功')
          this.getPtsDetails({ id: this.uId })
        })
        .catch(() => {
          this.$message.error('邀请失败')
        })
      }
    },
    getStatusList (list, status) {
      let name = ''
      if (list && list.length) {
        list.forEach(item => {
          if (item.id === status) {
            name = item.name
          }
        })
      }
      return name
    },
    goToMessage (value) {
      console.log(value)
      this.$emit('goToMessage', value)
    },
    agreement (value) {
      if (value === 2 && !this.refuseReason) {
        this.$message.error('请填写拒绝理由')
        return
      }
      this.spUpdatePtsAuth({ authStatus: value })
    },
    // 参与者审批
    spUpdatePtsAuth (parameter) {
      updatePtsAuth({ ...parameter, id: this.uId, refuseReason: this.refuseReason })
        .then((res) => {
          this.$message.success('审核完成')
          this.handleCancel()
        })
        .catch(() => {})
    },
    switchitem (item, value) {
      console.log(item, value)
      if (value === 'status') {
        this.form.status = item.id
        this.switchUpDatePtsStatus({ status: item.id })
      }
      if (value === 'reId') {
        this.switchUpdatePtsReId({ reId: item.id })
      }
    },
    // 修改当前状态
    switchUpDatePtsStatus (parameter) {
      upDatePtsStatus({ ...parameter, proId: this.proId, pId: this.ptsNo })
        .then((res) => {
          console.log(res)
          this.$message.success('状态修改成功')
        })
        .catch(() => {
          this.$message.error('状态修改失败')
        })
    },
    // 研究人员修改或添加
    switchUpdatePtsReId (parameter) {
      updatePtsReId({ ...parameter, proId: this.proId, ptsNo: this.ptsNo })
        .then((res) => {
          console.log(res)
          this.$message.success('研究人员修改成功')
        })
        .catch(() => {
          this.$message.error('研究人员修改失败')
        })
    },
    // 请求当前项目研究人员列表
    getDoctorList () {
      doctorList({ researchId: this.PtsDetailsList.researchId, proId: this.proId })
        .then((res) => {
          console.log(res)
          this.theDoctorList = res
        })
        .catch(() => {})
    },
    // 请求参与者详细信息
    getPtsDetails (parameter = {}) {
      PtsDetails({ ...parameter })
        .then((res) => {
          console.log(res)
          this.PtsDetailsList = res
          this.thisStatus = res.authStatus.toString()
          this.form.status = res.ptsStatus
          this.ptsNo = res.ptsNo
          this.form.reId = res.researchDetailId
          this.getDoctorList()
        })
        .catch(() => {})
    },
    // 状态回到
    handChange () {
      this.$message.success('操作成功')
    },
    // 关闭回调
    handleCancel () {
      this.refuseReason = ''
      this.form = this.$options.data().form
      this.PtsDetailsList = {}
      this.theDoctorList = []
      //   this.$message.success('操作成功')
      this.$emit('closeViewModal')
    }
  }
}
</script>
<style scoped lang="scss"></style>
