<template>
  <div>
    <a-modal
      width="80%"
      title="邀请参与者"
      :visible="visible"
      :footer="null"
      @cancel="handleCancel"
    >
      <a-form-model :rules="rules" ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
        <!-- <a-form-model-item label="受试者编号" prop="ptsNo">
          <a-input
            v-model="form.ptsNo"
            :maxLength="50"
            @change="e => form.ptsNo = handlePtsChange(e.target.value)"
          />
        </a-form-model-item> -->
        <a-form-model-item label="姓名" prop="ptsName">
          <a-input
            v-model="form.ptsName"
            :maxLength="50"
          />
        </a-form-model-item>
        <a-form-model-item label="身份证号" prop="ptsIdentity">
          <a-input
            v-model="form.ptsIdentity"
          />
        </a-form-model-item>
        <a-form-model-item label="手机号码" prop="ptsPhone">
          <a-input
            v-model="form.ptsPhone"
            :maxLength="11"
          />
        </a-form-model-item>
        <a-form-model-item label="参与者状态" prop="ptsStatus">
          <a-select
            v-model="form.ptsStatus"
          >
            <a-select-option v-for="(item, index) in $const.PTS_STATUS" :value="item.value" :key="index">
              {{ item.desc }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item >
          <div style="display:flex">
            <a-button @click="handleOk(1)">新增</a-button>
            <a-button style="margin-left:20px" type="primary" @click="handleOk(2)">新增&邀请</a-button>
          </div>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    researchId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      form: {
        // ptsNo: '',
        ptsName: '',
        ptsPhone: '',
        ptsStatus: '',
        ptsIdentity: ''
      },
      rules: {
        ptsNo: [{ required: true, trigger: 'change', message: '请输入受试者编号' }],
        ptsName: [{ required: true, trigger: 'change', message: '请输入姓名' }],
        ptsPhone: [
          { required: true, trigger: 'change', message: '请输入手机号格' },
          { trigger: 'blur', pattern: /^1[3-9]\d{9}$/, message: '手机号格式错误' }
        ],
        ptsIdentity: [
          { required: true, message: '请输入证件号码', trigger: 'change' },
          { trigger: 'blur', pattern: /^\d{6}((((((19|20)\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(((19|20)\d{2})(0[13578]|1[02])31)|((19|20)\d{2})02(0[1-9]|1\d|2[0-8])|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))0229))\d{3})|((((\d{2})(0[13-9]|1[012])(0[1-9]|[12]\d|30))|((\d{2})(0[13578]|1[02])31)|((\d{2})02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[048])0229))\d{2}))(\d|X|x)$/, message: '证件号码格式错误' }
        ],
        ptsStatus: [{ required: true, trigger: 'change', message: '请选择参与者状态' }]
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 15 }
    }
  },
  watch: {
    visible (e) {
      if (e) {
        this.form.researchId = this.researchId
      } else {
        this.form = this.$options.data().form
      }
    }
  },
  methods: {
    handleCancel () {
      this.$emit('cancel')
    },
    handlePtsChange (value) {
      value = this.$options.filters.checkUnChinese(value)
      return value
    },
    handleOk (type) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$emit('ok', [this.form], type)
        }
      })
    }
  }
}
</script>
<style scoped lang="less">
</style>
